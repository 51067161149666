h3 {
	font-size: $GlobalStyles-standard_font + 2px;
}


@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {

	h3 {
		font-size: $GlobalStyles-standard_font;
	}
}