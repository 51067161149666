@import '~app/scss/global/GlobalStyles.module';

$FormStyles-label_height  : 16px;
$FormStyles-input_box_size: ($GlobalStyles-input_sizing + 2px) - ($GlobalStyles-standard_space*3);


@mixin FormStyles-active_input() {
	@include GlobalStyles-card;
	outline: transparent;
}

.form {
	position: relative;


	&::after {
		@include GlobalStyles-pseudo;
		top    : 0px;
		left   : 0px;
		width  : 100%;
		height : 100%;
		z-index: 999;
		display: none;
	}

	&.loading {
		:global(.GlobalStyles-ghost_ui) {
			display: block;
		}
	}

	&.submitting {
		&::after {
			display: block;
		}

		.input_wrap {
			opacity: .5;
		}

		.is_main_button .button_spinner {
			display: inline-block;
		}
	}
}

.input_wrap {
	position      : relative;
	display       : inline-block;
	padding       : $GlobalStyles-standard_space;
	padding-bottom: $GlobalStyles-font_single_line + ($GlobalStyles-standard_space/4);

	&.has_error {

		> .input_container {

			> input, > select, > textarea {
				border-color: rgba($ColorStyles-perror, .7);
			}
		}
	}

		
	&.captcha_input_wrap {
		
		label {
			display: none;
		}
	}

	&.option_box_wrapper {

		> .input_boundary {
			@include GlobalStyles-clearfix;
			padding: (($GlobalStyles-standard_space)) $GlobalStyles-standard_space+($GlobalStyles-standard_space/2);

			> .input_wrap_background {
				z-index: -1;
			}

			> label {
				top      : -$GlobalStyles-standard_space;
				transform: none;
			}
		}

		&.radio {

			.option_box {

				.option_box_facade {
					border-radius: 50%;

					&::before {
						@include GlobalStyles-pseudo;
						border-radius: 50%;
						background   : rgba(darken($ColorStyles-sdark, 100%),.8);
						position     : relative;
						height       : 100%;
						width        : 100%;
						opacity      : 0;
						transition   : all $GlobalStyles-transition;
					}
				}
			}
		}


		&.checkbox {

			.option_box {
				
				.option_box_facade {

					.checkbox_check {
						@include GlobalStyles-center(absolute);
						opacity   : 0;
						transition: all $GlobalStyles-transition;
					}
				}
			}
		}

		.option_box_boundary {
			@include GlobalStyles-clearfix;
			padding : $GlobalStyles-standard_space 0px;
			display : inline-block;
		}

		.option_box {
			@include GlobalStyles-clearfix;
			padding       : $GlobalStyles-standard_space/2;
			position      : relative;
			display       : inline-block;
			padding-left  : $FormStyles-input_box_size;
			vertical-align: middle;

			&.option_box_has_toggle {

				.option_box_input {
					left: (($GlobalStyles-standard_space/2) + ($GlobalStyles-standard_space*1.5));
				}

				.option_box_space {
					@include GlobalStyles-card;
					background: darken($ColorStyles-plight, 5%);
					padding   : $GlobalStyles-standard_space*1.5;
				}
			}

			.option_box_input {
				@include GlobalStyles-v-center(absolute);
				cursor  : pointer;
				left    : 0px;
				opacity : 0;
				height  : $FormStyles-input_box_size;
				width   : $FormStyles-input_box_size;

				&:checked {

					~ .option_box_facade {
						border:1px solid rgba(darken($ColorStyles-sdark, 100%),.8);

						&::before, .checkbox_check  {
							opacity: 1;
						}
					}

					~ .option_box_toggle {
						opacity   : 1;
						max-height: 9999px;
						transition: max-height $GlobalStyles-transition cubic-bezier(1,0,1,0);
					}

					&:disabled { 

						~ .option_box_facade {

							&::before, .checkbox_check  {
								opacity: .7;
							}

						}
					}
				}

				&:focus {
					~ .option_box_facade {
						border:1px solid rgba(darken($ColorStyles-sdark, 100%),.8);
					}
				}

				&:disabled {
					~ .option_box_facade {
						border:1px solid rgba(darken($ColorStyles-sdark, 100%),.1);
					}

					~ .option_box_label {
						opacity: .7;
					}
				}
			}

			.option_box_facade {
				@include GlobalStyles-v-center(absolute);
				@include ColorStyles-plight;
				display      : inline-block;
				left         : 0px;
				height       : $FormStyles-input_box_size;
				line-height  : $FormStyles-input_box_size;
				width        : $FormStyles-input_box_size;
				border       : 1px solid rgba(darken($ColorStyles-sdark, 100%),.5);
				border-radius: $GlobalStyles-radius;
				padding      : $GlobalStyles-standard_space/4;
				transition   : all $GlobalStyles-transition; 
			}

			.option_box_label {
				display    : inline-block;
				float      : left;
				min-height : $FormStyles-input_box_size;
				line-height: $FormStyles-input_box_size + 1px;
				padding    : 0px $GlobalStyles-standard_space;
			}

			.option_box_toggle {
				display   : block;
				width     : 100%; 
				max-height: 0px;
				overflow  : hidden;
				opacity   : 0;
				transition: max-height $GlobalStyles-transition cubic-bezier(0,1,0,1);
			}
		}
	}

	&.upload_box {

		.input_container {
			padding      : $GlobalStyles-standard_space*3 0px;
			color        : rgba($ColorStyles-ptext, .5);
			text-align   : center;
			border       : 2px dashed rgba($ColorStyles-pdark, .8);
			background   : rgba($ColorStyles-primary, .2);
			padding-right: ($GlobalStyles-standard_font * 3) + ($GlobalStyles-standard_space * 2);

			.upload_box_placeholder {
				position     : relative;
				display      : inline-block;
				padding-left : ($GlobalStyles-standard_font * 4) + ($GlobalStyles-standard_space*3);

				.upload_box_icon {
					@include GlobalStyles-v-center(absolute);
					font-size  : $GlobalStyles-standard_font * 4; 
					left       : $GlobalStyles-standard_space*2;
	    			line-height: $GlobalStyles-standard_font * 4; 
				}

				.upload_box_text {
					font-size  : $GlobalStyles-standard_font * 2; 
					display    : inline-block;
				}
			}

			input {
				position: absolute;
				height  : 100%;
				width   : 100%;
				top     : 0px;
				left    : 0px;
				cursor  : pointer; 
				opacity : 0; 

				&:hover {
					~ .upload_box_edit {
						opacity: 1;
					}
				}
			}

			.upload_box_clear {
				position   : absolute;
				top        : $GlobalStyles-standard_space;
				right      : $GlobalStyles-standard_space;
				font-size  : $GlobalStyles-standard_font * 3;
				line-height: 0px;
				display    : block;
				z-index    : 5;
				cursor     : pointer;
				opacity    : .5;
				transition : all $GlobalStyles-transition;

				&:hover {
					opacity: 1;
				}
			}

			.upload_box_edit {
				position   : absolute;
				top        : 48px + ($GlobalStyles-standard_space*2);
				right      : $GlobalStyles-standard_space + ($GlobalStyles-standard_space/2);
				font-size  : $GlobalStyles-standard_font * 2;
				line-height: 0px;
				display    : block;
				cursor     : pointer;
				opacity    : .5;
				transition : all $GlobalStyles-transition;
				height     : 48px;
				line-height: 48px;
			}

			.upload_preview {
				position      : relative;
				display       : inline-block;
				padding       : $GlobalStyles-standard_space/2;
				padding-bottom: 20px;
				z-index       : 3;
				max-width     : 300px;
			}

			.upload_document_preview {
				font-size: $GlobalStyles-standard_font * 5;
			}

			.upload_file_name {
				position     : relative;
  				white-space  : nowrap; 
			    text-overflow: ellipsis;
			    overflow     : hidden;
				height       : 40px;
				line-height  : 40px;
				padding-right: 40px;
			}

			.upload_file_rotate {
				position    : absolute;
				right       : 0px;
				top         : 0px; 
				width       : 40px;
				line-height : 40px;
				text-align  : center;
				display     : inline-block;
				font-size   : $GlobalStyles-standard_font + 4px;
				color		: $ColorStyles-secondary;
			}

			.upload_image_preview {
				@include GlobalStyles-child-center(img);
				background  : darken($ColorStyles-primary, 5%);
				height		: 300px;
				width		: 300px;
			}

			.upload_image_rotate_90 img {
				transform	: rotate(90deg);
			}

			.upload_image_rotate_180 img {
				transform	: rotate(180deg);
			}

			.upload_image_rotate_270 img {
				transform	: rotate(270deg);
			}
		}
	}

	input, select, textarea {
		position : relative;
		z-index  : 2;
		font-size: $GlobalStyles-standard_font;
		width    : 100%;

		&:focus {
			@include FormStyles-active_input;
		}

		&:disabled {
			@include ColorStyles-p(.5);
		}
	}

	.input_container {
		@include GlobalStyles-clearfix;
		position: relative;
		display : block; 
	}

	label {
		position     : relative;
		display      : block;
		margin-bottom: $GlobalStyles-standard_space/2;

		.label_primary {
			font-weight: bold;
		}

		.label_secondary {
			margin-top: $GlobalStyles-standard_space/4;
		}

		.label_text {
			position  : relative;
			display   : inline-block;
		}

		.required {
			color: $ColorStyles-perror;
		}
	}

	.label_sub_text {
		margin-left: $GlobalStyles-standard_space/2;
		font-size  : $GlobalStyles-standard_font - 4px; 

		&.label_sub_nospace {
			margin-left: 0px;
		}
	}

	.input_wrap_error {
		position     : relative;
		width        : 100%;
		color        : $ColorStyles-perror;
		left         : 0px;
		margin-bottom: -$GlobalStyles-font_single_line;
		min-height   : $GlobalStyles-font_single_line;

		.input_error_content {
			background: lighten($ColorStyles-perror,46%);
			padding   : 0px $GlobalStyles-standard_space;
		}
	}

	:global(.SingleDatePicker),:global(.SingleDatePickerInput),:global(.DateInput) {
		width: 100%;
	}
}

.textbox, .dropdown,:global(.DateInput_input) {
	@include ColorStyles-plight;
	border: 1px solid darken($ColorStyles-primary,5%);
}

.textview {
	@include ColorStyles-p(.5);
    white-space: pre-line;
    padding    : $GlobalStyles-standard_space !important;
	min-height : $GlobalStyles-input_sizing;

    .textview_content {
    	line-height   : normal;
    	display       : inline-block;
    	vertical-align: middle;
    }
}

.textbox, .dropdown,.textview,:global(.DateInput_input) {
	transition   : $GlobalStyles-transition;
	border-radius: $GlobalStyles-radius;
	box-shadow   : none; 
	padding      : 0px $GlobalStyles-standard_space;
	
	&:focus {
		@include FormStyles-active_input;
	}
}

.textarea_input {
 	resize    : vertical; 
	border    : 1px solid darken($ColorStyles-primary,5%);
	max-height: 80px;
	min-height: $GlobalStyles-input_sizing;
	padding   : $GlobalStyles-standard_space;
}

.password input {
	padding-right: 34px;
}

.eye_wrap {
	z-index: 2;
	position: absolute;
	bottom: 8px;
	right: 10px;
	opacity: 0.8;
}

.textbox,:global(.DateInput_input) {
	min-height   : $GlobalStyles-input_sizing;
	line-height  : $GlobalStyles-input_sizing;
}

.dropdown {
	min-height   : $GlobalStyles-input_sizing + 2px;
	line-height  : $GlobalStyles-input_sizing + 2px;
	padding      : ($GlobalStyles-standard_space - 1px) $GlobalStyles-standard_space;
}

.rows {
	@include GlobalStyles-clearfix;
	margin : 0 auto;
}

.row_for_button {
	text-align: right;
}

.capctha_boundary {
	display    : block;
	width      : 100%;
	padding-top: $GlobalStyles-standard_space + ($GlobalStyles-standard_space/2);
}

.capctha {
	display: inline-block;
}

.capctha_content {
	border-radius      : 2px;
	display            : inline-block;
	transform-origin   : left;
  	backface-visibility: hidden;
  	max-width          : 100%; 
  	width              : 304px; 
  	min-height         : 78px;
  	line-height        : 0px;
}

.capctha_boundary {
	width   : 100%;
	position: relative;
}

.button_wrapper {
    padding : $GlobalStyles-standard_space;
    display : inline-block;
    position: relative;

    .button_space {
	    display : block;
	    position: relative;
    }
}

.button {
	@include GlobalStyles-clearfix;
    width    : 100%;
    min-width: 80px;
    
    + .button {
        margin-left: $GlobalStyles-standard_space;
    }

	.primary_button & {	
		@include GlobalStyles-button;
	}

	.secondary_button & {
		@include GlobalStyles-secondary_button;
	}

	.button_spinner {
		position    : relative;
		height      : 20px;
		width       : 20px;
		transform   : none;
		left        : 0px;
		top         : 0px;
		margin-top  : 6px;
		float       : left; 
		margin-right: $GlobalStyles-standard_space;
		display     : none;
	}
}


@media all and (max-width: ($GlobalStyles-min_width * 2)-1px) {
	.input_wrap {
		display: block;
		float  : none !important;
		width  : 100% !important;

		label {
			&.blank {
				display: none;
			}
		}
	}

	.id_types {

		.label_secondary .label_text {

			&::after {
				display: none !important;
			}
		}
	}
}

.button_wrapper {
	@include GlobalStyles-button_width_media;
}

.id_types {

	.label_secondary .label_text {

		&::after {
			display: inline-block;
			word-break: break-word;
			content: '\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0'
					+'\a0\a0\a0';
		}
	}
}

:global {

	.SingleDatePicker_picker {
		z-index: 5;
	}

	.SingleDatePickerInput__withBorder {
		border: 0px;
	}

	.CalendarMonth_caption {
		padding-top: 18px;
	}

	.DayPickerKeyboardShortcuts_show__bottomRight {

		&::before {
   			border-right: 33px solid $ColorStyles-secondary;
		}

   		&:hover {

			&::before {
   				border-right: 33px solid $ColorStyles-slight;
   			}
   		}
	}

	.CalendarDay__selected {
		background: $ColorStyles-secondary;
		border    : 1px solid $ColorStyles-secondary;

		&:hover {
			background: $ColorStyles-secondary;
			border    : 1px solid $ColorStyles-secondary;
		}
	}

	.DayPicker__withBorder {
		border-radius: $GlobalStyles-radius;
    	box-shadow   : 0px 5px 25px rgba($ColorStyles-sdark, 0.2), 
    				   0px 10px 15px rgba(darken($ColorStyles-sdark,50%), 0.3);
	}
}

.datepicker_select {
	height     : 33px;
	line-height: 33px;

	+ .datepicker_select {
		margin-left: $GlobalStyles-standard_space/2;
	}

	select {
		height     : 33px;
		line-height: 33px;
		border     : 1px solid $ColorStyles-primary;
	}
}

.form_modal_header {
	text-align: center;

	.modal_icon_container {
		text-align   : center;
		margin-top   : -((60px/2) + ($GlobalStyles-standard_space*2));
		margin-bottom: $GlobalStyles-standard_space * 2; 

		.form_modal_icon {
			font-size    : $GlobalStyles-standard_font * 3;
			display      : inline-block;
			margin       : 0 auto;
		    height       : 60px;
		    line-height  : 74px;
		    width        : 60px;
			color        : $ColorStyles-stext;
			border-radius: 50%;
		}
	}
}

@media all and (max-width: ($GlobalStyles-screen_mobile - ($GlobalStyles-min_width - 20))-1px) {
	.input_wrap {
		
		&.upload_box {
			.upload_preview {
				max-width: 240px !important;

				.upload_image_preview {
					width : 230px;
					height: 230px;
				}
			}
		}
	}
}

@import '~theme/components/form/scss/FormStyles.module';