$ColorStyles-primary  : #eaeaea;
$ColorStyles-plight   : #ffffff;
$ColorStyles-pdark    : #bdbec1;
$ColorStyles-ptext    : #090909;
$ColorStyles-perror   : red;

$ColorStyles-secondary: $secondary;
$ColorStyles-slight   : $slight;
$ColorStyles-sdark    : $sdark;
$ColorStyles-stext    : $stext;
$ColorStyles-ssuccess : $ssuccess;
$ColorStyles-sblue    : $sblue;
$ColorStyles-serror   : $serror;


@mixin ColorStyles-p($opacity: 1) {
    background-color: rgba($ColorStyles-primary, $opacity);
    color           : $ColorStyles-ptext;
}

@mixin ColorStyles-plight($opacity: 1) {
    background-color: rgba($ColorStyles-plight, $opacity);
    color           : $ColorStyles-ptext;
}

@mixin ColorStyles-pdark($opacity: 1) {
    background-color: rgba($ColorStyles-pdark, $opacity);
    color           : $ColorStyles-ptext;
}


@mixin ColorStyles-s($opacity: 1) {
    background-color: rgba($ColorStyles-secondary, $opacity);
    color           : $ColorStyles-stext;
}

@mixin ColorStyles-slight($opacity: 1) {
    background-color: rgba($ColorStyles-slight, $opacity);
    color           : $ColorStyles-stext;
}

@mixin ColorStyles-sdark($opacity: 1) {
    background-color: rgba($ColorStyles-sdark, $opacity);
    color           : $ColorStyles-stext;
}

@mixin ColorStyles-pdark-fading-edges() {
    background: -webkit-linear-gradient(
                    left, 
                    rgba($ColorStyles-pdark,0) 0%,
                    rgba($ColorStyles-pdark,0.3) 16%, 
                    rgba(darken($ColorStyles-pdark, 10%),0.7) 52%, 
                    rgba($ColorStyles-pdark,0.3) 87%, 
                    rgba($ColorStyles-pdark,0) 100%
                );
    background: -o-linear-gradient(
                    left, 
                    rgba($ColorStyles-pdark,0) 0%,
                    rgba($ColorStyles-pdark,0.3) 16%, 
                    rgba(darken($ColorStyles-pdark, 10%),0.7) 52%, 
                    rgba($ColorStyles-pdark,0.3) 87%, 
                    rgba($ColorStyles-pdark,0) 100%
                );
    background: linear-gradient(
                    to right, 
                    rgba($ColorStyles-pdark,0) 0%,
                    rgba($ColorStyles-pdark,0.3) 16%, 
                    rgba(darken($ColorStyles-pdark, 10%),0.7) 52%, 
                    rgba($ColorStyles-pdark,0.3) 87%, 
                    rgba($ColorStyles-pdark,0) 100%
                );
    filter: progid:DXImageTransform.Microsoft.gradient(
                startColorstr='#{$ColorStyles-pdark}', 
                endColorstr='#{$ColorStyles-pdark}',
                GradientType=1 
            );
}

@mixin ColorStyles-sdark-v-fading-edges() {
    background: -webkit-linear-gradient(
                    top, 
                    rgba($ColorStyles-sdark,0) 0%,
                    rgba($ColorStyles-sdark,0.15) 16%, 
                    rgba($ColorStyles-sdark,0.5) 52%, 
                    rgba($ColorStyles-sdark,0.15) 87%, 
                    rgba($ColorStyles-sdark,0) 100%
                );
    background: -o-linear-gradient(
                    top, 
                    rgba($ColorStyles-sdark,0) 0%,
                    rgba($ColorStyles-sdark,0.15) 16%, 
                    rgba($ColorStyles-sdark,0.5) 52%, 
                    rgba($ColorStyles-sdark,0.15) 87%, 
                    rgba($ColorStyles-sdark,0) 100%
                );
    background: linear-gradient(
                    to bottom, 
                    rgba($ColorStyles-sdark,0) 0%,
                    rgba($ColorStyles-sdark,0.15) 16%, 
                    rgba($ColorStyles-sdark,0.5) 52%, 
                    rgba($ColorStyles-sdark,0.15) 87%, 
                    rgba($ColorStyles-sdark,0) 100%
                );
    filter: progid:DXImageTransform.Microsoft.gradient(
                startColorstr='#{$ColorStyles-sdark}', 
                endColorstr='#{$ColorStyles-sdark}',
                GradientType=1 
            );
}

@mixin ColorStyles-p-diagonal() {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+26,2989d8+100&0+12,1+26,0+40 */
    background: -moz-linear-gradient(
                    -15deg, 
                    rgba(lighten($ColorStyles-primary,3%),0) 0%,
                    rgba(lighten($ColorStyles-primary,3%),1) 16%,
                    rgba(lighten($ColorStyles-primary,3%),0) 30%,
                    rgba(lighten($ColorStyles-primary,3%),0) 100%
                ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
                    -15deg,
                    rgba(lighten($ColorStyles-primary,3%),0) 0%,
                    rgba(lighten($ColorStyles-primary,33%),1) 16%,
                    rgba(lighten($ColorStyles-primary,3%),0) 30%,
                    rgba(lighten($ColorStyles-primary,3%),0) 100%
                ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
                    100deg, 
                    rgba(lighten($ColorStyles-primary,3%),0) 0%,
                    rgba(lighten($ColorStyles-primary,3%),1) 16%,
                    rgba(lighten($ColorStyles-primary,3%),0) 30%,
                    rgba(lighten($ColorStyles-primary,3%),0) 100%
                ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$ColorStyles-primary}', endColorstr='#{$ColorStyles-primary}',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}