//##
// GLobal variables and mixin that is dependent to project theme

$GlobalStyles-slant_angle_top    : -78px + $GlobalStyles-standard_space;
$GlobalStyles-slant_bg_selector  : ':global(.GlobalStyles-slant_container_background)';

@mixin GlobalStyles-card() {  
    border-radius: $GlobalStyles-radius;
    box-shadow   : 0px 2px 3px rgba($ColorStyles-sdark,.2);
}

@mixin GlobalStyles-link() {
    color          : $ColorStyles-secondary;
    text-decoration: none;
    transition     : all $GlobalStyles-transition;
    cursor         : pointer;

    &:hover {
        color          : $ColorStyles-slight;
        text-decoration: underline;
    }
}

@mixin GlobalStyles-link_secondary_theme() {
    a {

        color: adjust-hue(lighten($ColorStyles-secondary,10%),-30%);

        &:hover {
            color: adjust-hue(lighten($ColorStyles-secondary,30%),-30%);
        }
    }
}

@mixin GlobalStyles-slant_container($background_selector) {
    position: relative;
    display : block;

    &::before, &::after,
    body &:global(.GlobalStyles-slant_container_secondary::after), 
    body &:global(.GlobalStyles-slant_container_secondary::before) {
        @include ColorStyles-sdark;
        @include GlobalStyles-pseudo;
        width     : 100%;
        height    : 4px;
        left      : 0px;

        @media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
            @include ColorStyles-s;
        }
    }

    &::before {
        transform : skewY(-4deg);
        top       : 0px;
    }

    &::after {
        bottom: 0px;
    }

    #{$background_selector} {
        bottom: 0px;
        top   : 0px;

        &::before, &::after {
            @include ColorStyles-sdark;
            @include GlobalStyles-pseudo;
            width  : 100%;
            left   : 0px;
            z-index: -1;

            @media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
                @include ColorStyles-s;
            }
        }

        &::before {
            @include ColorStyles-sdark;
            @include GlobalStyles-pseudo;
            bottom   : 35%;
            transform: skewY(-4deg);
            top      : $GlobalStyles-standard_space;

            @media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
                @include ColorStyles-s;
            }
        }

        &::after {
            bottom: $GlobalStyles-standard_space;
            top   : ($GlobalStyles-slant_angle_top * -1) + $GlobalStyles-standard_space;
        }
    }

    // <nobackground>
    @media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
        @include ColorStyles-plight;
        border-top: 1px solid darken($ColorStyles-plight, 5%);

        &::before, &::after {
            display: none !important;
        }

        :global(.GlobalStyles-slant_container_background) {
            display: none;
        }
    }

}

// this is to give freedom to set the top of slant background
@mixin GlobalStyles-slant_container_top($top,$background_selector:$GlobalStyles-slant_bg_selector) {
    body &:global(.GlobalStyles-slant_container_secondary::before), &::before, #{$background_selector} {
        top: $top !important;
    }
}

// this is to give freedom to set the top of slant background
@mixin GlobalStyles-slant_container_bottom($bottom,$background_selector:$GlobalStyles-slant_bg_selector) {
    body &:global(.GlobalStyles-slant_container_secondary::after), &::after, #{$background_selector} {
        bottom: $bottom !important;
    }
}

@mixin GlobalStyles-floating_slight_card($strong_shadow: false) {
    @include ColorStyles-plight;
    border: 1px solid darken($ColorStyles-plight,15%);

    @if $strong_shadow {
        box-shadow: 0px 8px 25px rgba($ColorStyles-sdark,.4),0px 15px 15px rgba(darken($ColorStyles-sdark,100%),.5);
    } @else {
        box-shadow: 0px 8px 25px rgba($ColorStyles-sdark,.4),0px 15px 15px rgba(darken($ColorStyles-sdark,100%),.2);
    }
}


// this global classes is here instead in AppStyles 
// because the css rules are here.
@mixin GlobalStyles-global_classes() {

    :global {
        .GlobalStyles-slant_container_secondary {
            @include GlobalStyles-slant_container_bottom($GlobalStyles-standard_space * -5);
            @include GlobalStyles-slant_container('.GlobalStyles-slant_container_background');

            .GlobalStyles-slant_container_background {
                position: absolute;
                left    : 0px;
                width   : 100%;
            }
        }
    }

    :global(.GlobalStyles-ghost_ui) {
        position: absolute;
        top     : 0px;
        left    : 0px;
        height  : 100%;
        z-index : 100;
        width   : 100%;
        overflow: hidden;
        display : none;

        &::before {
            @include GlobalStyles-pseudo();
            background: lighten($ColorStyles-primary,5%);
            top       : 0px;
            left      : 0px;
            height    : 100%;
            width     : 100%;
        }

        &::after {
            @include GlobalStyles-pseudo();
            @include ColorStyles-p-diagonal();
            @include KeyframeStyles-ghost_ui_loading($GlobalStyles-transition*6 infinite);
            height    : 100%;
            width     : 100%;
            left      : 0px;
        }
    }
}

@mixin GlobalStyles-button_width_media() {

    @media all and (max-width: 420px) {
        width: 100%;
    }

}


@mixin GlobalStyles-button_structure() {
    @include GlobalStyles-button_width_media;
    padding        : 0 $GlobalStyles-standard_space*2;
    letter-spacing : 1px;
    height         : 36px;
    line-height    : 36px;
    border-radius  : $GlobalStyles-radius;
    font-weight    : bold; 
    text-transform : uppercase;
    display        : inline-block;
    text-decoration: none;
    text-align     : center;
    transition    : all $GlobalStyles-transition; 
}

@mixin GlobalStyles-button() {
    @include GlobalStyles-button_structure;
    @include ColorStyles-s;
    border: 1px solid $ColorStyles-secondary;
    text-decoration: none !important;

    @media (min-device-width:($GlobalStyles-screen-desktop)) {
        &:focus, &:hover {
            @include ColorStyles-slight;
            border    : 1px solid $ColorStyles-slight;
            box-shadow: 0 1px 1px 0 rgba($ColorStyles-secondary,0.3), 0 1px 3px 1px rgba($ColorStyles-secondary,0.2);
            outline   : 0px; 
        }
    }

    &:active, &:disabled, &[disabled] {
        @include ColorStyles-s();
        opacity   : .7;
        border    : 1px solid $ColorStyles-secondary;
        box-shadow: none;
        color     : rgba($ColorStyles-stext, .5);
    }
}

@mixin GlobalStyles-secondary_button() {
    @include GlobalStyles-button_structure;
    color     : $ColorStyles-secondary;
    box-shadow: none;
    border    : 1px solid lighten($ColorStyles-slight,35%);
    background: transparent;


    @media (min-device-width:($GlobalStyles-screen-desktop)) {
        &:focus, &:hover {
            color     : $ColorStyles-sdark;
            border    : 1px solid lighten($ColorStyles-slight,20%);
            outline   : 0px; 
        }
    }

    &:active, &:disabled, &[disabled] {
        border    : 1px solid lighten($ColorStyles-slight,40%);
        box-shadow: none;
        color     : rgba($ColorStyles-slight, .5);
    }

}

@mixin GlobalStyles-form_secondary_theme_primary_button() {
    @include ColorStyles-plight;
    color     : $ColorStyles-secondary;
    box-shadow: none;
    border    : 1px solid lighten($ColorStyles-slight,35%);
    box-shadow: 0 1px 1px 0 rgba($ColorStyles-sdark,0.3), 0 1px 3px 1px rgba($ColorStyles-sdark,0.2);


    @media (min-device-width:($GlobalStyles-screen-desktop)) {
        &:focus, &:hover {
            color     : $ColorStyles-sdark;
            border    : 1px solid lighten($ColorStyles-slight,20%);
            outline   : 0px; 
        }
    }

    &:active, &:disabled, &[disabled] {
        @include ColorStyles-p();
        border    : 1px solid $ColorStyles-primary;
        box-shadow: none;
        color     : rgba($ColorStyles-slight, .8);
        opacity   : .8; 
    }
}

@mixin GlobalStyles-form_secondary_theme_secondary_button() {
    background: transparent !important;
    border    : 0px !important;
    color     : adjust-hue(lighten($ColorStyles-secondary,20%),-30%);
    box-shadow: none;

    @media (min-device-width:($GlobalStyles-screen-desktop)) {
        &:focus, &:hover {
            color     : adjust-hue(lighten($ColorStyles-secondary,30%),-20%);
            outline   : 0px; 
        }
    }

    &:active, &:disabled, &[disabled] {
        color  : adjust-hue(lighten($ColorStyles-secondary,10%),-20%);
        opacity: .8; 
    }
}

@mixin GlobalStyles-form_secondary_theme() {

    :global {

        .GlobalStyles-label {
            color: $ColorStyles-stext;
        }

        .GlobalStyles-primary_button .GlobalStyles-button {
            @include GlobalStyles-form_secondary_theme_primary_button;
        }

        .GlobalStyles-secondary_button .GlobalStyles-button {
            @include GlobalStyles-form_secondary_theme_secondary_button;
        }

        .FormStyles-option_box {

            input:checked ~ .FormStyles-option_box_facade {
                border-color: $ColorStyles-plight !important;
            }

            .FormStyles-option_box_facade {
                border-color: rgba($ColorStyles-plight,.5) !important;

                &::before {
                    background: $ColorStyles-plight !important;
                }
            }
        }
    }
}

@mixin GlobalStyles-block() {
    @include GlobalStyles-clearfix;
    border-bottom: 1px solid $ColorStyles-primary;
    padding      : $GlobalStyles-standard_space $GlobalStyles-standard_space*2;
    word-break   : break-word; 
}

@mixin GlobalStyles-block_start() {
    border-top: 1px solid $ColorStyles-primary;
    margin-top: $GlobalStyles-standard_space*2;
}

@mixin GlobalStyles-block_div() {
    width: 50%;
    float: left;

    @media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
        width: 100%;
        float: none;
    }
}


@mixin GlobalStyles-block_content() {
    @include GlobalStyles-clearfix;
}


@mixin GlobalStyles-block_label() {
    padding-right: $GlobalStyles-standard_space*2;
    font-weight  : bold; 


    @media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
        margin-bottom: $GlobalStyles-standard_space;
        padding-right: $GlobalStyles-standard_space;
    }
}


@mixin GlobalStyles-block_content_colon() {
    margin-left : -($GlobalStyles-standard_space);
    float       : left;

    @media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
        display: none;
    }
}


@mixin GlobalStyles-block_content_text() {
    padding-left: $GlobalStyles-standard_space;
    display     : inline-block;
    float       : left;

    @media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
        margin-bottom: $GlobalStyles-standard_space;
    }
}


@mixin GlobalStyles-block_ul() {
    margin      : 0px;
    padding-left: $GlobalStyles-standard_space*2;
}

@mixin GlobalStyles-block_top() {
    margin-top   : $GlobalStyles-standard_space * 2 !important;
}

@mixin GlobalStyles-block_bottom() {
    margin-bottom   : $GlobalStyles-standard_space * 2 !important;
}
