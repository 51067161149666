// ---------------------------------------------------------------------------------------------------------------------
//   From http://tobiasahlin.com/spinkit/
// ---------------------------------------------------------------------------------------------------------------------

@mixin KeyframeStyles-sk_circleBounceDelay($animation_details...) {

	@if (index($KeyframeStyles-loaded, sk-circleBounceDelay) == null) {

		$KeyframeStyles-loaded: append($KeyframeStyles-loaded,sk-circleBounceDelay) !global;
		@at-root (without: all) {
			@keyframes sk-circleBounceDelay {
				0%, 80%, 100% {
				            transform: scale(0);
				} 
				
				40% {
				    transform: scale(1);
				}
			}
		}
	}

	animation: sk-circleBounceDelay $animation_details;
}

@mixin KeyframeStyles-ghost_ui_loading($animation_details...) {

	@if (index($KeyframeStyles-loaded, ghost_ui_loading) == null) {

		$KeyframeStyles-loaded: append($KeyframeStyles-loaded,ghost_ui_loading) !global;
		@at-root (without: all) {
			@keyframes ghost_ui_loading {
				0% {
				   	transform: skew(-10deg) translateX(0%);
				   	opacity  : 1;
				}   
				
				100% {
				   	transform: skew(-10deg) translateX(100%);
				   	opacity  : 0;
				}
			}
		}
	}

	animation: ghost_ui_loading $animation_details;
}