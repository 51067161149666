/**
 * --------------------------------------------------------------------------------------------------------------------- 
 *  Nunito Regular
 * ---------------------------------------------------------------------------------------------------------------------
 */


/* vietnamese */
@font-face {
    font-family: 'Nunito';
    font-style : normal;
    font-weight: 400;
    src        : local('Nunito Regular'),
                 local('Nunito-Regular'),
                 url(../../theme/fonts/googlenunito/Nunito-Regular.woff2) format('woff2'),
                 url(../../theme/fonts/googlenunito/Nunito-Regular.ttf) format("truetype"),
                 url(../../theme/fonts/googlenunito/Nunito-Regular.eot) format("embedded-opentype");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Nunito';
    font-style : normal;
    font-weight: 400;
    src        : local('Nunito Regular'),
                 local('Nunito-Regular'),
                 url(../../theme/fonts/googlenunito/Nunito-Regular.woff2) format('woff2'),
                 url(../../theme/fonts/googlenunito/Nunito-Regular.ttf) format("truetype"),
                 url(../../theme/fonts/googlenunito/Nunito-Regular.eot) format("embedded-opentype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Nunito';
    font-style : normal;
    font-weight: 400;
    src        : local('Nunito Regular'),
                 local('Nunito-Regular'),
                 url(../../theme/fonts/googlenunito/Nunito-Regular.woff2) format('woff2'),
                 url(../../theme/fonts/googlenunito/Nunito-Regular.ttf) format("truetype"),
                 url(../../theme/fonts/googlenunito/Nunito-Regular.eot) format("embedded-opentype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/**
 * --------------------------------------------------------------------------------------------------------------------- 
 *  Nunito Bold
 * ---------------------------------------------------------------------------------------------------------------------
 */


/* vietnamese */
@font-face {
    font-family: 'Nunito';
    font-style : normal;
    font-weight: 700;
    src        : local('Nunito Bold'), 
                 local('Nunito-Bold'), 
                 url(../../theme/fonts/googlenunito/Nunito-Bold.woff2) format('woff2'),
                 url(../../theme/fonts/googlenunito/Nunito-Bold.ttf) format("truetype"),
                 url(../../theme/fonts/googlenunito/Nunito-Bold.eot) format("embedded-opentype");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Nunito';
    font-style : normal;
    font-weight: 700;
    src        : local('Nunito Bold'), 
                 local('Nunito-Bold'), 
                 url(../../theme/fonts/googlenunito/Nunito-Bold.woff2) format('woff2'),
                 url(../../theme/fonts/googlenunito/Nunito-Bold.ttf) format("truetype"),
                 url(../../theme/fonts/googlenunito/Nunito-Bold.eot) format("embedded-opentype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Nunito';
    font-style : normal;
    font-weight: 700;
    src        : local('Nunito Bold'), 
                 local('Nunito-Bold'), 
                 url(../../theme/fonts/googlenunito/Nunito-Bold.woff2) format('woff2'),
                 url(../../theme/fonts/googlenunito/Nunito-Bold.ttf) format("truetype"),
                 url(../../theme/fonts/googlenunito/Nunito-Bold.eot) format("embedded-opentype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/**
 * --------------------------------------------------------------------------------------------------------------------- 
 *  Roboto Regular
 * ---------------------------------------------------------------------------------------------------------------------
 */

/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style : normal;
    font-weight: 400;
    src        :local('Roboto'),
                local('Roboto-Regular'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Regular.eot) format("embedded-opentype");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style : normal;
    font-weight: 400;
    src        :local('Roboto'),
                local('Roboto-Regular'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Regular.eot) format("embedded-opentype");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
    font-family: 'Roboto';
    font-style : normal;
    font-weight: 400;
    src        :local('Roboto'),
                local('Roboto-Regular'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Regular.eot) format("embedded-opentype");
    unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
    font-family: 'Roboto';
    font-style : normal;
    font-weight: 400;
    src        :local('Roboto'),
                local('Roboto-Regular'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Regular.eot) format("embedded-opentype");
    unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
    font-family: 'Roboto';
    font-style : normal;
    font-weight: 400;
    src        :local('Roboto'),
                local('Roboto-Regular'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Regular.eot) format("embedded-opentype");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style : normal;
    font-weight: 400;
    src        :local('Roboto'),
                local('Roboto-Regular'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Regular.eot) format("embedded-opentype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Roboto';
    font-style : normal;
    font-weight: 400;
    src        :local('Roboto'),
                local('Roboto-Regular'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Regular.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Regular.eot) format("embedded-opentype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, 
                U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/**
 * --------------------------------------------------------------------------------------------------------------------- 
 *  Roboto Bold
 * ---------------------------------------------------------------------------------------------------------------------
 */

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style : normal;
  font-weight: 700;
  src        :  local('Roboto Bold'), 
                local('Roboto-Bold'), 
                url(../../theme/fonts/googleroboto/Roboto-Bold.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Bold.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Bold.eot) format("embedded-opentype");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style : normal;
  font-weight: 700;
  src        :  local('Roboto Bold'), 
                local('Roboto-Bold'), 
                url(../../theme/fonts/googleroboto/Roboto-Bold.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Bold.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Bold.eot) format("embedded-opentype");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style : normal;
  font-weight: 700;
  src        :  local('Roboto Bold'), 
                local('Roboto-Bold'), 
                url(../../theme/fonts/googleroboto/Roboto-Bold.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Bold.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Bold.eot) format("embedded-opentype");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style : normal;
  font-weight: 700;
  src        :  local('Roboto Bold'), 
                local('Roboto-Bold'), 
                url(../../theme/fonts/googleroboto/Roboto-Bold.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Bold.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Bold.eot) format("embedded-opentype");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style : normal;
  font-weight: 700;
  src        :  local('Roboto Bold'), 
                local('Roboto-Bold'), 
                url(../../theme/fonts/googleroboto/Roboto-Bold.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Bold.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Bold.eot) format("embedded-opentype");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style : normal;
  font-weight: 700;
  src        :  local('Roboto Bold'), 
                local('Roboto-Bold'), 
                url(../../theme/fonts/googleroboto/Roboto-Bold.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Bold.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Bold.eot) format("embedded-opentype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style : normal;
  font-weight: 700;
  src        :  local('Roboto Bold'), 
                local('Roboto-Bold'), 
                url(../../theme/fonts/googleroboto/Roboto-Bold.woff2) format('woff2'),
                url(../../theme/fonts/googleroboto/Roboto-Bold.ttf) format("truetype"),
                url(../../theme/fonts/googleroboto/Roboto-Bold.eot) format("embedded-opentype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, 
                U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}